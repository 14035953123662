import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/agent/sales-order/cart`;

export default {
  index(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "show",
      },
    });
  },
  count(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "count",
      },
    });
  },
  update(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update",
      },
    });
  },
  addProduct(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "add-product",
      },
    });
  },
  setReason(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "set-reason",
      },
    });
  },
  removeProduct(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "remove-product",
      },
    });
  },
  clear(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "clear",
      },
    });
  },
  setOutlet(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "set-outlet",
      },
    });
  },
  setAddress(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "set-address",
      },
    });
  },
  setSalesperson(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "set-salesperson",
      },
    });
  },
  checkout(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "checkout",
      },
    });
  },
};
